<template>
  <div>
    <b-card no-body :style="'color:' + d_menuData.style.color + '; background-color: ' + d_menuData.style.backgroundColor">
      <component-top-menu :p_menuData="d_menuData" :p_selectedMenu="'general'"></component-top-menu>
      <b-row>
        <b-col cols="12">
          <template v-if="d_totalLogCount > d_logResultPageData['per-page']">
            <b-pagination v-model="d_logResultPageData['current']" :total-rows="d_totalLogCount" :per-page="d_logResultPageData['per-page']" aria-controls="log-table"></b-pagination>
          </template>
          <b-table v-if="d_totalLogCount > 0" id="log-table" head-variant="dark" striped responsive stacked="/*sm*/" ref="ref_logList" bordered small hover :items="logListRows" :fields="logListColumns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-dblclicked="" @row-hovered="" @row-unhovered="">
            <template v-slot:cell(rowNumber)="row">
              {{ ((d_logResultPageData['current'] - 1) * d_logResultPageData['per-page']) + row.index + 1 }}
            </template>
            <template v-slot:cell(date)="row">
              {{ moment(row.item.date).format("DD/MM/YYYY HH:mm") }}
            </template>
            <template v-slot:cell(userNameAll)="row">
              {{ row.item.userFirstName + ' ' + row.item.userLastName }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-modal v-if="d_showSearchSettings" v-model="d_showSearchSettings" size="md" title="Arama Ayarları" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              Başlangıç Tarihi
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input type="date" id="d_startingDate" v-model="d_startingDate"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              Bitiş Tarihi
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input type="date" id="d_endingDate" v-model="d_endingDate"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row style="margin-top:25px">
        <b-col cols="6">
          Log Türü Filtreleme:
        </b-col>
        <b-col cols="6">
          <b-form-checkbox-group v-model="d_selectedLogTypes">
            <template v-for="log_type in d_allLogTypes">
              <b-form-checkbox :name="log_type.value" :value="log_type.value">{{ log_type.label }}</b-form-checkbox>
            </template>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_showTextFilter" v-model="d_showTextFilter" size="md" title="Arama İfadesi" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="4">
          Arama İfadesi
        </b-col>
        <b-col cols="8">
          <b-input type="text" id="d_searchText" placeholder="Arama İfadesi Giriniz" v-model="d_searchText"></b-input>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogService from '@/services/log';
import store from '@/store';
import moment from 'moment';
import {
  default as ComponentTopMenu
} from '@/components/widgets/ComponentTopMenu';
// import {
//   default as Modal
// } from '@/components/widgets/Modal';

export default {
  name: 'Logs',
  components: {
    ComponentTopMenu
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      d_showSearchSettings: false,
      d_showTextFilter: false,
      d_menuData: {
        'style': {
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'color': '#000000',
          'main_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#878484'
          }
        },
        'list': [{
          'label': 'Genel',
          'icon': require('@/icon/2979152.png'),
          'value': 'general',
          'list': [{
            'icon': '',
            'label': 'Genel',
            'value': 'general',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Ayarlar',
              'icon': require('@/icon/1584256.png'),
              'label': '',
              'value': 'general',
              'click': this.f_showSearchSettings
            }, {
              'type': 'button', // button, buttongroup, color
              'title': 'Filtreleme',
              'icon': require('@/icon/2447096.png'),
              'label': '',
              'value': 'wdmnamelanguage',
              'click': this.f_showTextFilter
            }]
          }, {
            'icon': '',
            'label': 'Güncelle',
            'value': 'refresh',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Verileri Güncelle', // save
              'icon': require('@/icon/615577.png'),
              'label': '',
              'value': 'f_getLogs',
              'click': this.f_getLogs
            }]
          }]
        }, {
          'label': 'Yardım',
          'icon': require('@/icon/2249810.png'),
          'value': 'help',
          'list': [{
            'icon': require('@/icon/2249810.png'),
            'label': 'Genel',
            'value': 'general',
            'list': []
          }]
        }]
      },
      d_logResultPageData: { 'current': 1, 'per-page': 100 },
      logListColumns: [{
        key: "rowNumber",
        label: " "
      }, {
        key: "date",
        label: "Tarih",
        sortable: true
      }, {
        key: "userNameAll",
        label: "Kullanıcı Adı",
        sortable: true
      }, {
        key: "hospitalGroup",
        label: "Hastane Grubu",
        sortable: true
      }, {
        key: "hospitalCode",
        label: "Hastane Kodu",
        sortable: true
      }, {
        key: "codeType",
        label: "Kod",
        sortable: true
      }, {
        key: "info",
        label: "Bilgi"
      }, {
        key: "typeError",
        label: "Type Error",
        sortable: true
      }, {
        key: "valueError",
        label: "Value Error",
        sortable: true
      }],
      moment: moment,
      d_searchText: '',
      logListRows: [],
      user: {},
      d_startingDate: '',
      d_endingDate: '',
      d_selectedLogTypes: ['error', 'information'],
      d_showMode: true,
      d_allLogTypes: [{ 'value': 'error', 'label': 'Error' }, { 'value': 'information', 'label': 'Information' }],
      d_searchTextTimeout: 0,
      d_totalLogCount: 0
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_startingDate = moment(new Date()).format('YYYY-MM-DD');
    this.d_endingDate = moment(new Date()).format('YYYY-MM-DD');
  },
  mounted: function () {
    this.f_getLogs();
  },
  destroyed () {
    window.clearTimeout(this.d_searchTextTimeout);
  },
  methods: {
    f_showSearchSettings: function () {
      this.d_showSearchSettings = true;
    },
    f_showTextFilter: function () {
      this.d_showTextFilter = true;
    },
    f_getLogs: function (keep_page=false) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Loglar güncelleniyor.' } });
      if (!keep_page) {
        this.d_logResultPageData['current'] = 1;
      }
      this.d_totalLogCount = 0;
      if (this.d_selectedLogTypes.length === 0) {
        for (let i in this.d_allLogTypes) {
          this.d_selectedLogTypes.push(this.d_allLogTypes[i].value);
        }
      }
      LogService.getLogs({ 'log_type': this.d_selectedLogTypes, 'starting_date': this.d_startingDate, 'ending_date': this.d_endingDate, 'pagination': this.d_logResultPageData, 'search_text': this.d_searchText })
        .then(resp => {
          this.d_showMode = false;
          if (resp.data.status === 'success') {
            this.logListRows = resp.data.result.log_list;
            this.d_totalLogCount = resp.data.result.log_count;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error', resp.data.message);
          }
        });
    }
  },
  watch: {
    'd_searchText': function () {
      let text = this.d_searchText;
      this.d_searchTextTimeout = setTimeout(function () {
        if (text === this.d_searchText) {
          this.f_getLogs();
        }
      }.bind(this), 2000);
    },
    'd_logResultPageData.current': function () {
      this.f_getLogs(true);
    }
  }
}

</script>

